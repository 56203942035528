import styled from "@emotion/styled";
import React from "react";
import { FiChevronLeft } from "react-icons/fi";

import { t } from "~src/designSystem/theme";

import { OldSpanUseTextInstead } from "../../deprecated/Span";
import { Flexbox } from "../../layout/Flexbox";

export type IBackButtonProps = {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
};

export const BackButton: React.FC<IBackButtonProps> = ({ onClick, children, className }) => {
  return (
    <Container
      className={className}
      row
      paddingBottom="12px"
      paddingX="12px"
      alignItems="center"
      onClick={onClick}
    >
      <IconBackground className="icon-bg">
        <FiChevronLeft color="#fff" size={16} />
      </IconBackground>
      <OldSpanUseTextInstead color="subtitle" marginLeft="12px" lineHeight={1}>
        {children}
      </OldSpanUseTextInstead>
    </Container>
  );
};

const IconBackground = styled(Flexbox)`
  border-radius: 100%;
  width: ${t.space[5].toString()};
  height: ${t.space[5].toString()};
  font-size: 8px;
  align-items: center;
  justify-content: center;
`;

const Container = styled(Flexbox)`
  pointer: cursor;

  &:hover {
    color: ${(props) => props.theme.components.BackButton.hover};
  }
  &:hover .icon-bg {
    transition: ${(props) => props.theme.transition};
    background: ${(props) => props.theme.components.BackButton.hover};
  }
`;
