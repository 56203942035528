/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { BiPlusCircle } from "react-icons/bi";

import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { BackButton } from "~src/designSystem/molecules/BackButton";
import { t } from "~src/designSystem/theme";
import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { useConnectBank } from "~src/shared/dataSources/bank/hooks/useConnectBank";
import { ConsoleLog } from "~src/shared/helpers";

import { IPayoutAccount } from "./PayoutAccounts";

type IProps = {
  accounts: IPayoutAccount[];
  refetchVendor: () => Promise<void>;
  setIsSelectingPayoutMethod: (arg0: boolean) => void;
  payoutTo?: IPayoutAccount;
  setPayoutMethod: (arg0: string) => void;
  height?: string;
  width?: string;
  /**
   * Where to redirect after connect success
   */
  redirectPath?: IAllowedRedirectPath;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerStyle?: any;
  // /**
  //  * Source for connection for analytics
  //  */
  // source?: string;
};

export const SelectPayoutMethod: React.FC<IProps> = (props) => {
  const onNewPayoutMethodSuccess = async () => {
    await props.refetchVendor();
    ConsoleLog("Success, revalidated vendor");
  };

  const { open: openPlaidNew } = useConnectBank({
    onSuccess: onNewPayoutMethodSuccess,
    redirectPath: props.redirectPath ?? "/inbox",
    // TODO(johnrjj) - Custom source prop?
    source: "trade-orderbox-add-payout-flow",
  });

  return (
    <FlexWrapper
      height={props.height !== undefined ? props.height : "600px"}
      paddingBottom="12px"
      paddingTop="20px"
      width={props.width !== undefined ? props.width : ""}
      style={props.containerStyle}
    >
      <BackButton onClick={() => props.setIsSelectingPayoutMethod(false)}>Payout Method</BackButton>
      {props.accounts.map((acc) => {
        let active = false;
        if (props.payoutTo !== undefined) {
          active = props.payoutTo.public_id === acc.public_id;
        }

        return (
          <Selection
            key={acc.public_id}
            row
            margin="16px"
            marginY="8px"
            padding="8px"
            active={active}
            onClick={() => {
              props.setPayoutMethod(acc.public_id);
              props.setIsSelectingPayoutMethod(false);
            }}
          >
            {acc.plaid_item != null && (
              <>
                <Flexbox
                  row
                  alignItems="center"
                  css={css`
                    margin-right: 14px;
                  `}
                >
                  <img
                    src={`data:image/png;base64, ${acc.plaid_item.institution_logo}`}
                    height="17"
                    width="17"
                    css={css`
                      outline: none !important;
                    `}
                  />
                </Flexbox>
                <OldSpanUseTextInstead
                  color={active ? "title" : "subtitle"}
                  weight={500}
                  marginRight="8px"
                  css={css`
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  {acc.plaid_item.institution_name}
                </OldSpanUseTextInstead>
              </>
            )}
            <OldSpanUseTextInstead
              // gray-200
              color="title"
              weight={500}
              marginLeft="auto"
              marginRight="4px"
              css={css`
                flex-shrink: 0;
              `}
            >
              ••• {acc.mask}
            </OldSpanUseTextInstead>
          </Selection>
        );
      })}
      <Selection row marginX="16px" marginY="8px" padding="8px" onClick={() => openPlaidNew()}>
        <OldSpanUseTextInstead color="subtitle">
          <Flexbox
            row
            alignItems="center"
            marginRight="16px"
            css={css`
              height: 100%;
              font-size: 17px;
            `}
          >
            <BiPlusCircle />
          </Flexbox>
        </OldSpanUseTextInstead>
        <OldSpanUseTextInstead color="subtitle" weight={500}>
          Add a payout method
        </OldSpanUseTextInstead>
      </Selection>
    </FlexWrapper>
  );
};

const FlexWrapper = styled(Flexbox)`
  overflow-y: auto;
`;

const Selection = styled(Flexbox)<{ active?: boolean }>`
  border-radius: ${t.radii[4].toString()};
  transition: ${(props) => props.theme.transition};
  background-color: ${(props) =>
    props.active === true &&
    props.theme.components.OrderBox.SelectPayoutMethod.Selection.background.active};

  &:hover {
    background-color: ${(props) =>
      props.theme.components.OrderBox.SelectPayoutMethod.Selection.background.hover};
  }
`;
